import React from 'react'
import {
	AddShoppingCartOutlined,
	RemoveShoppingCartOutlined
} from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import c from 'classnames'

import styles from './Photo.module.scss'

import {
	MediaItemInterface,
	PricelistItemInterface,
	ReducerStoreInterface
} from '../../utils/interfaces'
import { STORAGE } from '../../utils/consts'
import {
	setCart,
	setCartAdditional,
	setPhotoModal
} from '../../store/reducers/data'

interface PhotoInterface {
	photo: MediaItemInterface
	step: number
}

const Photo = ({ photo, step }: PhotoInterface) => {
	const dispatch = useDispatch()
	const { pricelist, cart, cartAdditional } = useSelector(
		(reduxStore: ReducerStoreInterface) => reduxStore.data
	)

	const [qty, setQty] = React.useState(0)
	const [attributes, setAttributes] = React.useState<PricelistItemInterface>()
	const [optionsStep, setOptionsStep] = React.useState(0)
	const [removedSteps, setRemovedSteps] = React.useState<number[]>([])

	const addToCartHandler = (key?: number) => {
		if (photo.collection_name === 'dodatne') {
			const updatedCart = [
				...cartAdditional,
				{
					key: key,
					id: photo.id,
					file_name: photo.file_name,
					quantity: qty,
					collection_name: photo.collection_name,
					attributes: attributes,
					price: attributes?.price
				}
			]
			dispatch(setCartAdditional(updatedCart))
			setOptionsStep(optionsStep + 1)
			setQty(0)
			setAttributes(undefined)
		} else {
			const updatedCart = [
				...cart.filter((c: MediaItemInterface) => c.id !== photo.id),
				{
					id: photo.id,
					file_name: photo.file_name,
					quantity: 1,
					collection_name: photo.collection_name,
					attributes: attributes,
					price: attributes?.price
				}
			]
			dispatch(setCart(updatedCart))
		}
	}

	const removeFromCartHandler = (key: number) => {
		if (photo.collection_name === 'dodatne') {
			const others = []
			const same = []
			for (const cartItem of cartAdditional) {
				if (cartItem.id !== photo.id) {
					others.push(cartItem)
				} else {
					same.push(cartItem)
				}
			}
			const updatedSame = same.filter((s) => s.key !== key)
			const updatedCart = [...others, ...updatedSame]
			dispatch(setCartAdditional(updatedCart))
			setRemovedSteps([...removedSteps, key])
		} else {
			const updatedCart = [
				...cart.filter((c: MediaItemInterface) => c.id !== photo.id)
			]
			dispatch(setCart(updatedCart))
		}
	}

	const isAddedToCard = (key: number) => {
		if (step === 4) {
			return !!cartAdditional.find(
				(c) =>
					c.id === photo.id && c.key === key && c.collection_name === 'dodatne'
			)
		} else {
			return !!cart.find((c) => c.id === photo.id)
		}
	}

	const isAddable = (key: number) => {
		let addable = false
		if (step === 4) {
			addable = !cartAdditional.find(
				(c) =>
					c.id === photo.id && c.key === key && c.collection_name === 'dodatne'
			)
		} else {
			addable = !cart.find((c) => c.collection_name === photo.collection_name)
		}
		if (addable) {
			if (step === 4) {
				if (qty > 0) {
					if (attributes) {
						return (
							<button
								className={styles.add}
								onClick={() => addToCartHandler(key)}
							>
								DODAJ <AddShoppingCartOutlined />
							</button>
						)
					}
				}
			} else {
				return (
					<button className={styles.add} onClick={() => addToCartHandler(key)}>
						DODAJ <AddShoppingCartOutlined />
					</button>
				)
			}
		}
	}

	const getOptions = () => {
		const options: any[] = []
		for (let i = 0; i <= optionsStep; i++) {
			options.push(
				<div
					className={c(
						styles.options,
						removedSteps.includes(i) && styles.hidden
					)}
					key={i}
				>
					{step === 4 && (
						<React.Fragment>
							{pricelist.items.length > 0 && (
								<label>
									<span>Opcije</span>
									<select
										name="format"
										id="format"
										onChange={(event) => {
											const pricelistItem = pricelist.items.find(
												(item) => item.id.toString() === event.target.value
											)
											setAttributes(pricelistItem)
										}}
										disabled={isAddedToCard(i)}
									>
										<option value="" hidden selected>
											Odaberite opciju...
										</option>
										{pricelist.items.map((item, index) => (
											<option value={item.id} key={index}>
												{item.name} ({item.price} din.)
											</option>
										))}
									</select>
								</label>
							)}
							<label>
								<span>Količina</span>
								<input
									type="number"
									defaultValue={0}
									min={0}
									onChange={(event) =>
										Number(event.target.value) > 0 &&
										setQty(Number(event.target.value))
									}
									disabled={isAddedToCard(i)}
								/>
							</label>
						</React.Fragment>
					)}
					<div className={styles.buttonWrapper}>
						{isAddedToCard(i) && (
							<button
								className={styles.remove}
								onClick={() => removeFromCartHandler(i)}
							>
								UKLONI <RemoveShoppingCartOutlined />
							</button>
						)}
						{isAddable(i)}
					</div>
				</div>
			)
		}
		return options
	}

	const removeExtensionFromFileName = (fileName: string) => {
		try {
			return fileName.replace(/\.[a-zA-Z0-9]{3,4}$/, '')
		} catch (e) {
			console.error('Error removing extension from file name:', e)
			return fileName
		}
	}

	return (
		<div className={styles.photo}>
			<div className={styles.id}>
				{removeExtensionFromFileName(photo.file_name)}
			</div>
			<img
				src={`${STORAGE}/${photo.id}/${photo.file_name}`}
				alt={photo.file_name}
				onClick={() => dispatch(setPhotoModal(photo))}
				title="Klikni za uvećanje"
			/>
			<div className={styles.optionsWrapper}>{getOptions()}</div>
		</div>
	)
}

export default Photo
